/*----- 19. Product details page  ---*/

.description-review-topbar {
  display: flex;
  justify-content: center;

  border-bottom: 1px solid #d7d7d7;
  .nav-item {
    a {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;

      margin: 0 15px 0;
      padding: 0 0 13px;

      color: #666;
      border-bottom: 3px solid transparent;
      background-color: transparent;
      &.active {
        color: #333;
      }
      @media #{$xs-layout} {
        font-size: 15px;

        margin: 0 4px 15px;
        padding: 0 0 8px;
      }
      @media #{$sm-layout} {
        font-size: 18px;

        margin: 0 8px 0 0;
        padding: 0 5px 0;
      }

      &.active {
        border-bottom: 3px solid $theme-color;
        background-color: transparent;
      }

      &.nav-link {
        border-radius: 0;
      }
    }
  }
}

.description-review-bottom {
  padding: 33px 0 0;
  p {
    font-size: 15px;
  }
}

.product-img-badges {
  position: absolute;
  z-index: 3;
  top: 24px;
  left: 20px;
  @media (max-width: 991px) {
    top: 12px;
    right: 20px;
    left: auto;
    justify-items: right;
  }
  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    width: fit-content;

    display: block;

    margin-bottom: 10px;
    padding: 3px 11px;

    color: #fff;
    border-radius: 3px;
    &.pink {
      background-color: $theme-color-secondary;
    }
    &.tag {
      background-color: #ff9900;
    }
  }

.product-large-image-wrapper {
  position: relative;

  }

  &--slider {
    .ht-swiper-button-nav {
      font-size: 50px;

      visibility: hidden;

      padding: 0;

      transition: 0.3s;

      opacity: 0;
      color: #333;
      border: none;
      background: none;
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  .single-image {
    img {
      width: 100%;
      border-radius: $card-border-radius;
    }
  }
}

.product-small-image-wrapper {
  .ht-swiper-button-nav {
    visibility: hidden;

    opacity: 0;
    color: #333;
    border: none;
    background: none;
    i {
      font-size: 40px;
    }
  }
  .single-image {
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;

      opacity: 1;
    }
  }
  .single-image {
    height: 100%;
    img {
      height: 100%;
      border-radius: $card-border-radius;

      object-fit: cover;
      object-position: center;
    }
  }
  .swiper-container-vertical {
    height: 100%;
  }

  &--side-thumb {
    overflow: hidden;

    height: 630px;
    @media #{$lg-layout,
    $md-layout,
    $xs-layout} {
      height: auto;
      margin-top: 15px;
    }
  }
}
