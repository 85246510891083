/*----- 9. Banner style  -------*/

@media #{$xs-layout} {
  .banner-area.pt-80 {
    padding-top: 60px;
  }
  .save-money-area {
    .pt-100 {
      padding-top: 50px;
    }
    .pb-100 {
      padding-bottom: 50px;
    }
  }
}


.cta-content {
  .title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.3;

    margin-bottom: 15px;

    @media #{$sm-layout} {
      font-size: 35px;
    }

    @media #{$xs-layout} {
      font-size: 25px;
    }
  }
  .text {
    font-size: 18px;
    font-weight: 500;

    margin-bottom: 30px;
    span {
      color: #053399;
    }
  }
  .cta-btn {
    font-size: 16px;

    display: inline-block;

    padding: 15px 30px;

    color: $white;
    background-color: #000;

    &:hover {
      background-color: #053399;
    }
  }
}

/** Region banner */
.region-banner-content {
  position: relative;
  z-index: 9;
  h2 {
    font-size: 34px;

    margin: 0;

    color: $theme-text;
    @media #{$xs-layout} {
      font-size: 24px;
    }
  }

  p {
    font-size: 16px;
  }

  .region-banner-btn {
    margin-top: 25px;

    @media #{$xs-layout} {
      margin-top: 20px;
    }

    a {
      border-radius: $btn-border-radius;

      line-height: 1;

      display: inline-block;

      padding: 15px 30px;

      color: #010101;
      border: 1px solid #333;
      @media #{$md-layout} {
        padding: 15px 35px 17px;
      }
      @media #{$xs-layout} {
        padding: 15px 35px 17px;
      }
      &:hover {
        color: #fff;
        border: 1px solid $theme-color;
      }
    }
  }
}

.region-banner-image {
  img {
    max-width: 500px;
    @media #{$xs-layout} {
      max-width: 300px;
    }
  }
}
