/*------- 5. Section title style  -------*/

.section-title {
  @media #{$xs-layout} {
    &.mb-50 {
      margin-bottom: 30px;
    }
  }
  h2 {
    color: $theme-text;
    font-size: 34px;
    font-weight: 600;

    position: relative;

    display: inline-block;

    margin: 0 0 0;
    @media #{$xs-layout} {
      font-size: 24px;
      line-height: 30px;
      &.mb-50 {
        margin-bottom: 30px;
      }
    }
    &::before {
      position: absolute;
      top: 17px;
      left: -100px;

      width: 80px;
      height: 2px;

      content: "";

      background-color: #000;
      @media #{$xs-layout} {
        left: -25px;

        width: 15px;
      }
    }
    &::after {
      position: absolute;
      top: 17px;
      right: -100px;

      width: 80px;
      height: 2px;

      content: "";

      background-color: #000;
      @media #{$xs-layout} {
        right: -25px;

        width: 15px;
      }
    }
  }
  &.mb-55 {
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;

    margin-top: 10px;

    &.grey {
      color: #808080;
    }
  }

  &.no-border {
    h2 {
      font-size: 36px;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
