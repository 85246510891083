/*------ 16. Breadcrumb style  --------*/

.breadcrumb-area {
  padding-top: 25px;

  ol li {
    display: flex;
  }
  @media #{$xs-layout} {
    padding-top: 12px;
    margin-bottom: 0;
  }
}
