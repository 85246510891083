/*-------- 4. Support style  -------*/

/* support hm3 */
.support-wrap-2 {
  .support-content-2 {
    h5 {
      font-size: 22px;
      line-height: 24px;

      margin: 15px 0 15px;

      color: #494949;
    }
    p {
      font-size: 15px;

      width: 80%;
      margin: 0 auto;

      color: #6a6a6a;
      @media #{$md-layout} {
        width: 100%;
      }
    }
  }
  &:hover .support-content-2 img {
    animation: 500ms ease-in-out 0s normal none 1 running tada;
  }
  &.support-shape {
    position: relative;
    &:before {
      position: absolute;
      top: 50%;
      right: -14px;

      width: 1px;
      height: 61px;

      content: "";
      transform: translateY(-50%);

      background-color: $theme-header;

      @media #{$xs-layout} {
        display: none;
      }
    }
  }
  &.support-shape-2 {
    border: 4px solid #fff;
  }
  &.support-shape-3 {
    border: 4px solid #f6f6f6;
  }
  &.support-padding-2 {
    padding: 36px 10px 30px;
    @media #{$lg-layout} {
      padding: 36px 5px 30px;
    }
    @media #{$xs-layout} {
      padding: 36px 6px 30px;
    }
  }
}

@media #{$xs-layout} {
  .support-area {
    &.pt-80 {
      padding-top: 30px;
    }
  }
}

.feature-icon-two-wrap {
  .col-md-4 {
    &:last-child {
      .support-wrap-2,
      .support-wrap-5 {
        &.support-shape {
          &:last-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}
